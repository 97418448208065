<template>
    <div class="event-summary">
        <div class="meta">
            <TypeLabel :name="eventType" :pageLanguage="pageLanguage" />
            <AttributionLabel v-if="attributions" :attributions="attributions" :pageLanguage="pageLanguage">
            </AttributionLabel>
        </div>
        <div class="head">
            <Typography as="h1" variant="h2">{{ title }}</Typography>

            <div class="date-time" :class="{ past: isPastEvent }">
                <span class="date">
                    <template v-if="isConference">
                        {{ ConferenceDateRange }}
                    </template>
                    <template v-else-if="pageLanguage !== 'en'">
                        {{ formatDateIntnl(props.eventDate.start, pageLanguage) }}
                    </template>
                    <template v-else>
                        {{ EventDate }}
                    </template>
                </span>

                <span v-if="!isPastEvent && !isConference" class="time">
                    {{ EventTimeRange }} ({{ eventDate.timeZone }})
                </span>
            </div>

            <div v-if="eventLocation" class="location">
                <Icon name="location" size="small" />
                <Typography as="span" variant="body">{{ eventLocation }}</Typography>
            </div>
        </div>

        <div class="controls">
            <div v-if="!isPastEvent" class="event-buttons">
                <Button v-if="eventRSVP.url" :url="eventRSVP.url" :newTab="isLinkExternal(eventRSVP)" variant="primary">
                    {{
                        $t('register to attend', 1, {
                            locale: pageLanguage,
                        })
                    }}
                </Button>

                <Button v-if="marketoCampaign.enabled" @click="revealForm" variant="primary">
                    {{
                        $t('register to attend', 1, {
                            locale: pageLanguage,
                        })
                    }}
                </Button>

                <Button @click="addToCalendar" iconPosition="left" variant="secondary">
                    Add to calendar
                    <template #icon>
                        <Icon name="calendar" />
                    </template>
                </Button>
            </div>

            <div class="share-buttons">
                <ShareButton />
                <CopyLinkButton />
            </div>
        </div>
    </div>
</template>

<script setup>
import { createEvent } from 'ics';
const { pageLanguage } = usePageDataStore();

const props = defineProps({
    attendanceType: {
        type: String,
    },
    attributions: {
        type: [Array, Object],
    },
    eventDate: {
        type: Object,
    },
    eventLocation: {
        type: String,
    },
    eventRSVP: {
        type: Object,
    },
    eventType: {
        type: String,
        default: '',
    },
    title: {
        type: String,
        default: '',
    },
    marketoCampaign: {
        type: Object,
    },
});

const isPastEvent = computed(() => {
    return dateRelationEvents(props.eventDate.end) === 'past';
});

const isConference = computed(() => {
    return props.eventType === 'conference';
});

const EventDate = computed(() => {
    return formatDate(props.eventDate.start, 'eee. MMMM do, yyyy');
});

const EventTimeRange = computed(() => {
    return timeRange(props.eventDate.start, props.eventDate.end);
});

const ConferenceDateRange = computed(() => {
    return dateRange(props.eventDate.start, props.eventDate.end);
});

const ICalEvent = {
    start: formatICalDate(props.eventDate.startTimestamp, props.eventType),
    end: formatICalDate(props.eventDate.endTimestamp || props.eventDate.end, props.eventType),
    title: props.title,
    location: props.location,
    // FIXME: add rsvp url after BE handles validation for urls
    // url: props.eventRSVP.url,
};

async function addToCalendar() {
    const filename = 'Event.ics';
    const file = await new Promise((resolve, reject) => {
        createEvent(ICalEvent, (error, value) => {
            if (error) {
                reject(error);
            }
            resolve(new File([value], filename, { type: 'text/calendar' }));
        });
    });
    const url = URL.createObjectURL(file);

    // trying to assign the file URL to a window could cause cross-site
    // issues so this is a workaround using HTML5
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = filename;

    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);

    URL.revokeObjectURL(url);
}

function revealForm() {
    const formLocation = document.getElementById('registrationForm');
    formLocation.classList.toggle('hidden');
}
</script>

<style lang="scss" scoped>
.meta {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 1rem;
    width: 100%;
    gap: 0.8rem;
}

.head {
    margin-bottom: vertical-space(1);
}

.date-time {
    margin-bottom: 0.8rem;

    .date {
        margin-inline-end: 1.2rem;
    }

    .time,
    &.past {
        color: color(noir, default, 0.5);
    }
}

.location {
    display: flex;
    gap: 0.4rem;

    svg {
        color: color(blue, default, 0.4);
        @include square(2rem);
    }

    span {
        color: color(noir, default, 0.5);
    }
}

.controls {
    display: flex;
    gap: var(--col-gutter);
    align-items: center;
    border-bottom: $border;
    padding-bottom: 2.4rem;

    .event-buttons,
    .share-buttons {
        display: inline-flex;
    }

    .event-buttons {
        gap: 0.8rem;
    }

    .share-buttons {
        gap: 0.4rem;
    }
}
</style>
